/* File: header.component.html
Created By: Kajal on 07.11.2022
Copyright (C) 2022-Present, Octanics Software LLP. All rights reserved.
*/

import {Component, OnInit, NgZone, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Localstorage} from '../../common/localstorage';
import {TokenService} from '../../common/token.service';
import {Lookup} from "../../common/lookup";
import {AuthService} from "../../../../auth.service";
import {Authorisation} from "@app/common/authorisation";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Editeroptions} from "@app/common/editeroptions";
// variable as the name of the function inside clipboard.min.js

declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }

  // file was object File
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  public options: Object = {
    htmlAllowedAttrs: 'align: left'
  };

  // count=1;
  // background:string;
//  loading = true;

  constructor(private router: Router, private http: Http, private title: Title, private lookUp: Lookup, private httpclient: HttpClient,
              private ngZone: NgZone, private authService: AuthService, private auth: Authorisation, private Localsecurestorage: Localstorage, private Token: TokenService, private sanitizer: DomSanitizer) {

  }

  //   router.events.subscribe((event: RouterEvent) => {
  //     this.navigationInterceptor(event);
  //   });
  // }
  //
  // // Shows and hides the loading spinner during RouterEvent changes
  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     this.loading = true;
  //   }
  //   if (event instanceof NavigationEnd) {
  //     setTimeout(() => { // here
  //       this.loading = false;
  //     }, 2000);
  //   }
  //
  //   // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   if (event instanceof NavigationCancel) {
  //     setTimeout(() => { // here
  //       this.loading = false;
  //     }, 2000);
  //   }
  //   if (event instanceof NavigationError) {
  //     setTimeout(() => { // here
  //       this.loading = false;
  //     }, 2000);
  //   }



  intervalId: any;
  EmailForm: FormGroup;
  firstname: any;
  rolename: any;
  lastname: any;
  userid: any;
  expiringdays: any;
  latedays: any;
  licenseerrormessage: any;
  licenseMessage: any;
  shortName: any;
  profilephoto: any;
  id: any;
  refresh: any;
  emailvalue: any;
  successMsg = '';
  navItems: any = [];
  roleId: any;
  landingroute: any;
  junctionbox = Utility.junctionboxPath();
  businesslogo: any;
  businesses: any = [];
  business: any = [];
  business_id: any = localStorage.getItem('businessid');
  emailbody = null;
  uploadingFilePercents = 0;
  uploadingFile = false;
  emailAttachments = [];
  emailAttachmentData: any = {};
  selectedFile: ImageSnippet;
  selectedFilePath: any;
  companyShortName = Utility.getCompanyShortName();
  junctionBoxSubDomain = Utility.junctionBoxSubDomain();
  marketdelta: any;
  ria: any;
  autobots: any;
  businessValue : any;
  isAccessible : any;
  user_id: any = localStorage.getItem('userid');
  role_id: any = localStorage.getItem('roleid');
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])

  resetTimer() {
    // this.authService.notifyUserAction();
  }

  getBusiness() {
    this.http.get(Configuration.serviceCoreURL + '/getbusinessesbyrole?user_id=' + this.user_id + '&role_id=' + this.role_id).subscribe(data => {
      this.businesses = data.json();
    });
  }

  onBusinessChange(event) {
    this.businessValue = event;
    console.log(this.businessValue);
    let type = this.businesses.find(x => x.id == this.businessValue);
    this.isAccessible = type['isAccessible'];
    let businessname = type['name'];
    if(this.isAccessible == 'true') {
      if (confirm('Are you sure you want to change the business?')) {
        localStorage.removeItem('businessid');
        localStorage.removeItem('companyname');
        this.business_id = this.businessValue;
        localStorage.setItem('businessid', this.business_id);
        localStorage.setItem('companyname', businessname);
        // this.business_id = localStorage.getItem('businessid');
        this.http.get(Configuration.serviceCoreURL + '/getbusinessdetails?business_id=' + this.business_id).subscribe(data => {
          data = data.json();
          this.business = data['businessdetails'];
          if (!Utility.isNull(this.business)){
            this.businesslogo = this.business['businesslogo'];
          }
        });
        if (localStorage.getItem('landingroute') == 'null') {
          this.router.navigate(['/login']);
        } else {
          if(localStorage.getItem('landingroute') == this.router.url){
            window.location.reload();
          }else{
            this.router.navigateByUrl(localStorage.getItem('landingroute'));
          }
        }

      } else {
        setTimeout(() => {
          this.business_id = localStorage.getItem('businessid');
        }, 2);
      }
    }else{
      alert("You do not have access to this business, please contact administrator.");
      setTimeout(() => {
        this.business_id = localStorage.getItem('businessid');
      }, 1);
    }
  }

  /*onEmailToChange(args) {
    if (args.target.value.length > 0)
      $('#emailto_errormsg').html('');
    else
      $('#emailto_errormsg').html('Add at least one email address');
  }*/

  onEmailMessageChange(args) {
    this.emailbody = args;

    if (!Utility.isNull(this.emailbody))
      $('#emailbody_errormsg').html('');
  }

  getEmailAttachmentData(event) {
    if (event.target.files.length > 0) {
      let filename = event.target.files[0].name;

      if (event.target.files) {
        for (let i = 0; i < event.target.files.length; i++) {
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = (events: any) => {
            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
            this.uploadEmailAttachment(this.selectedFile.file);
          };
        }
      }
    }
  }

  uploadEmailAttachment(emailattachment: any) {
    const formData = new FormData();
    formData.append('file', emailattachment);
    formData.append('filename', emailattachment.name);
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/productimages');
    formData.append('subdomain', this.junctionBoxSubDomain);

    let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
      reportProgress: true
    });

    this.httpclient.request(req).subscribe(event => {
      this.selectedFilePath = event['body'];

      this.emailAttachmentData = {
        'previewfilepath': this.selectedFilePath,
        'filepath': this.junctionbox + this.selectedFilePath,
        'filename': emailattachment.name
      };

      this.emailAttachments.push(this.emailAttachmentData);

      this.emailAttachments = this.emailAttachments.filter(x => x.previewfilepath != undefined);

      $('#emailattachment').val('');

      // Via this API, you get access to the raw event stream.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
        this.uploadingFile = true;
        // Look for upload progress events.
      } else if (event instanceof HttpResponse) {
        this.uploadingFile = false;
      }
    });
  }

  sendEmail(emailvalues) {
    let emailto;
    let emailcc = null, emailbcc = null, emailfrom = null;

    if (!Utility.isNull(emailvalues.emailto)) {
      emailto = emailvalues.emailto.split(';');
    }

    if (!Utility.isNull(emailvalues.emailcc)) {
      emailcc = emailvalues.emailcc.split(';');
    }

    if (!Utility.isNull(emailvalues.emailbcc)) {
      emailbcc = emailvalues.emailbcc.split(';');
    }
    if (!Utility.isNull(emailvalues.emailfrom)) {
      emailfrom = emailvalues.emailfrom.split(';');
    }

    for (let i in emailto) {
      emailto[i] = emailto[i].trim();
    }

    for (let i in emailcc) {
      emailcc[i] = emailcc[i].trim();
    }

    for (let i in emailbcc) {
      emailbcc[i] = emailbcc[i].trim();
    }

    for (let i in emailfrom) {
      emailfrom[i] = emailfrom[i].trim();
    }

    if (Utility.isNull(emailvalues.emailto))
      $('#emailto_errormsg').html('Add at least one email address');
    else
      $('#emailto_errormsg').html('');

    if (Utility.isNull(this.emailbody))
      $('#emailbody_errormsg').html('Message is Required');
    else
      $('#emailbody_errormsg').html('');

    if (!Utility.isNull(emailvalues.emailto) && !Utility.isNull(this.emailbody)) {
      let emailData = {
        'to': emailto,
        'cc': emailcc,
        'bcc': emailbcc,
        'from': emailfrom,
        'subject': emailvalues.emailsubject,
        'emailbody': this.emailbody,
        'emailattachments': this.emailAttachments,
        'isattached': this.emailAttachments.length > 0,
        'companyshortname': Utility.getCompanyShortName(),
        'loggingurl': Utility.serviceLoggingURL()
      };

      this.http.post(Utility.serviceIntegrationURL() + '/sendheaderemail', emailData).subscribe(data => {
        this.clearEmailControls();
        data = data.json();
        if (data['original']['success'] == 'true') {
          $('#emailsuccessmessage').html('Email sent successfully');
          $('#emailfailuremessage').html('');
        } else {
          $('#emailsuccessmessage').html('');
          $('#emailfailuremessage').html('Email not sent successfully');
        }
        // $('.modal').modal('hide');
      });
    }
  }

  clearEmailControls() {
    $('#frmSendEmail').get(0).reset();
    this.emailAttachments = [];
    this.emailbody = null;
  }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
  //   console.log(event);
  //   if (event.storageArea == localStorage) {
  //     console.log('d');
  //     let token = localStorage.getItem('userid');
  //     if (token == undefined) {
  //       console.log('ed');
  //       // Perform logout
  //       //Navigate to login/home
  //       this.onLogout();
  //     }
  //
  //
  //   }
  // }

  // @HostListener('window:load', ['$event'])
  // unloadHandler(event) {
  //   this.router.navigate(['clients']);
  // }
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event) {
  //   this.onLogout();
  // }
  getModules() {
    this.http.get(Configuration.serviceCoreURL + '/getmodulearea?role_id=' + this.roleId).subscribe((data) => {
      this.navItems = data.json();
      this.navItems.forEach( element =>{
        element.icon = this.sanitizer.bypassSecurityTrustHtml(element.icon);
      } );
    });
  }

  sendMail(email: string) {
    this.http.post(Configuration.serviceCoreURL + '/sendmail', email).subscribe(
      (res) => {
        this.successMsg = 'Email Sent Successfully!';
      },
      (err) => {
        console.log(err);
      });
  }
  getProductDropdown(){
    this.http.get(Configuration.serviceCoreURL + '/getparameterbycode?code=MKTDELTA').subscribe(data => {
      data = data.json();
      this.marketdelta = data['value'];
    });
    this.http.get(Configuration.serviceCoreURL + '/getparameterbycode?code=RIA').subscribe(data => {
      data = data.json();
      this.ria = data['value'];
    });
    this.http.get(Configuration.serviceCoreURL + '/getparameterbycode?code=AUTOBOTS').subscribe(data => {
      data = data.json();
      this.autobots = data['value'];
    });
  }
  // getAuthorised = function (module: string, area: string) {
  //
  //  this.auth.isAuthorised(module, area).subscribe(res => {
  //    if(res['id']!==''){
  //  return '1';
  //     //console.log('true');
  //
  //     } else {
  //
  //      return '0';
  //       // return typeof(false);
  //     }
  //   });
  //
  //
  //   // return this.http.get(Configuration.serviceCoreURL + '/getmodulearea?module=' + module + '&area=' + area).subscribe(
  //   //   (result) =>{
  //   // if ()result['_body']==='' {
  //   //   return true;
  //   // }else{
  //   //   return false;  }
  //   //   });
  //    //  if (data['_body']==0) {
  //    // console.log(true);
  //    //  }else{
  //    //    console.log(false);
  //    //
  //    //  }
  //
  //
  // }

  LogoutUserSetFlag() {
    this.http.get(Configuration.serviceCoreURL + '/updateloggedinstatusdeactive?id=' + this.id).subscribe(res => {
    });
  }


  onLogout() {
    this.LogoutUserSetFlag();
    this.Token.logout();

    // this.router.navigate(['/']);

    // sessionStorage.clear();
    //   localStorage.removeItem('email');
    //   localStorage.removeItem('username');
    //  localStorage.removeItem('userid');
    this.licenseMessage = null;
    /*sessionStorage.removeItem('licenseerrormessage');
    sessionStorage.removeItem('expiringdays');*/
    // localStorage.removeItem('employee_id');
    // console.log( sessionStorage.clear());
    //localStorage.removeItem('employee_id');
    //localStorage.removeItem('employeeid');
    // localStorage.removeItem('companyname');
    // localStorage.removeItem('companymobileno');
    // console.log( 'l',localStorage.removeItem( 'userid'));
    // console.log(  '0',localStorage.removeItem('employeeid'));
    //this.router.navigate(['/']);

  }

  //global serach open close
  toggleGlobalSearch() {
    var x = document.getElementById("global-search-box");
    var y = document.getElementById("global-search-hide-content");
    if (x.style.display === "block") {
      x.style.display = "none";
      y.style.display = "flex";
    } else {
      x.style.display = "block";
      y.style.display = "none";
    }
  }

  //responsive open close
  burgerMenuSwitch () {
    if ($("#header-responsive").hasClass("header-responsive-open")) {
      this.closeMenu()
    }else{
      $("#menu-line-upper").addClass("line-upper-rotated")
      $("#menu-line-lower").addClass("line-lower-rotated")
      $("#header-responsive").addClass("header-responsive-open")
    }
  }

  //responsive menu  close
  closeMenu(){
    if ($("#header-responsive").hasClass("header-responsive-open")) {
      $("#menu-line-upper").removeClass("line-upper-rotated")
      $("#menu-line-lower").removeClass("line-lower-rotated")
      $("#header-responsive").removeClass("header-responsive-open")
      $(".mobile-header-sub-menu").css("display","none")
      $(".extra-sub-content").css("display","none")
    }
  }

  //responsive menu and submenu open close
  toggleDivContentWithId(id) {
    var x = document.getElementById(`content-${id}`);
    if (x.style.display === "block") {

      x.style.display = "none";
    } else {
      $(".mobile-header-sub-menu").css("display","none")
      $(".extra-sub-content").css("display","none")
      x.style.display = "block";
    }
  }

  toggleDivSubContent(id) {
    var x = document.getElementById(`content-${id}`);
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      $(".extra-sub-content").css("display","none")
      x.style.display = "block";
    }
  }

  ngOnInit() {

    $('#emailbodyeditor').froalaEditor({key: Utility.editorLicenseKey,height: 200,   placeholderText: "<br/>",toolbarButtons: Editeroptions.editerOptions()});
    this.getBusiness();
    this.getProductDropdown();
    // $( ".navbar-collapse" ).toggle( "slide" );
    // Close Navbar when clicked outside
    // $(window).on('click', function(event){
    //   $('.menuMobileContainer #collapseExample.collapse').removeClass('show');
    //   $("#mennubar2MobileMenu .mobileMenuToggle button").attr("aria-expanded","false");
    // });

    // $('.menuMobileContainer').click(function(event){
    //   event.stopPropagation();
    // });
    this.http.get(Configuration.serviceCoreURL + '/getbusinessdetails?business_id=' + this.business_id).subscribe(data => {
      data = data.json();
      this.business = data['businessdetails'];
      if (!Utility.isNull(this.business))
        this.businesslogo = this.business['businesslogo'];
    });

    this.emailvalue = this.Localsecurestorage.getStorage('useemail');

    this.firstname = localStorage.getItem('firstname');
    this.id = this.Localsecurestorage.getStorage('useuserid');
    this.roleId = this.Localsecurestorage.getStorage('useroleid');
    this.lastname = localStorage.getItem('lastname');
    this.rolename = localStorage.getItem('aliasrolename');
    this.profilephoto = localStorage.getItem('profilephoto');
    this.landingroute = this.Localsecurestorage.getStorage('uselandingroute');

    /*if (sessionStorage.getItem('licenseerrormessage') != null) {
      this.licenseMessage = sessionStorage.getItem('licenseerrormessage');
      console.log('header - in if');
      console.log(sessionStorage.getItem('licenseerrormessage'));
    } else {
      this.latedays = sessionStorage.getItem('expiringdays');
      console.log('header - in else');
      console.log(sessionStorage.getItem('expiringdays'));
      this.licenseMessage = 'Your license is expiring in ' + this.latedays + ' days!!';
    }*/

    this.shortName = this.firstname.charAt(0) + this.lastname.charAt(0);
    // console.log('e',sessionStorage.getItem('eid'));
    // console.log('l',localStorage.getItem('employeeid'));
    this.getModules();


    this.EmailForm = new FormGroup({
      email: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
    });

    /*this.updateSubscription = interval(1000).subscribe(
      (val) => {
        console.log('this.ngOnInit()');
      });*/

    /*let win = (window as any);
    if(win.location.search === '?loaded' ) {
      win.location.search = '?loaded=1';
      win.location.reload('?loaded=1');
    }*/
  }


  public activeElement = 1;

  public selectedItem(id) {
    this.activeElement = id;
  }

  // onChange(){
  //   if(this.count%2==0){
  //     this.background='header .mainnav black';
  //     this.count++;
  //   }
  //   else {
  //     this.background="header bg-danger";
  //     this.count++;
  //   }
  // }
}

