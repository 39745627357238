//File: cancelledserialnumberlist.component.ts
//Created By: pradeep valiya on 02.12.2022
//Copyright (C) 2022-Present, Octanics Software LLP. All rights reserved.
import {Component, OnInit} from '@angular/core';
import {Http} from "@angular/http";
import {Title} from "@angular/platform-browser";
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from "../../../common/common";
import {ActivatedRoute, Router} from '@angular/router';
import {Sorting} from 'app/common/sorting';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-cancelledserialnumberlist',
  templateUrl: './cancelledserialnumberlist.component.html',
  styleUrls: ['./cancelledserialnumberlist.component.scss']
})
export class CancelledserialnumberlistComponent implements OnInit {


  constructor(private http: HttpClient, private title: Title, private router: Router, private route: ActivatedRoute) {
  }

  Sorting = Sorting;
  cancelledserialnumbers:any={};
  searchText;
  p: any = 1;
  Common = Common;
  count: any = 20;
  type: any;
  order: boolean = true;
  property: any = null;

  sorting(property, obj) {
    $("#"+property).remove();
    $(".sort-arrow").remove();
    if (property == this.property) {
      this.property = property;
      this.order = !this.order;
      if (this.order) {
        $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
        $('.'+property).append(" <svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"\n" +
          "                     class=\"turboBusiness-customers-list-table-header-name-icon sort-arrow\" id=\""+ property + "\" transform=\"matrix(1,0,0,-1,0,0)\">\n" +
          "                  <path fill-rule=\"evenodd\" clip-rule=\"evenodd\"\n" +
          "                        d=\"M15.235 12.528c.28-.323.344-.779.164-1.166a1.1 1.1 0 0 0-.999-.635h-3.3V3.091C11.1 2.488 10.608 2 10 2c-.608 0-1.1.488-1.1 1.09v7.637H5.6a1.1 1.1 0 0 0-1 .635c-.179.387-.115.843.165 1.166l4.4 5.091a1.104 1.104 0 0 0 1.67 0l4.4-5.09Z\"\n" +
          "                        fill=\"currentColor\" />\n" +
          "                </svg>");
      } else {
        $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
        $('.'+property).append(" <svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"\n" +
          "                     class=\"turboBusiness-customers-list-table-header-name-icon sort-arrow\" id=\""+ property + "\">\n" +
          "                  <path fill-rule=\"evenodd\" clip-rule=\"evenodd\"\n" +
          "                        d=\"M15.235 12.528c.28-.323.344-.779.164-1.166a1.1 1.1 0 0 0-.999-.635h-3.3V3.091C11.1 2.488 10.608 2 10 2c-.608 0-1.1.488-1.1 1.09v7.637H5.6a1.1 1.1 0 0 0-1 .635c-.179.387-.115.843.165 1.166l4.4 5.091a1.104 1.104 0 0 0 1.67 0l4.4-5.09Z\"\n" +
          "                        fill=\"currentColor\" />\n" +
          "                </svg>");
      }
      Sorting.sort(property, obj, this.order);
    } else {
      this.property = property;
      this.order = false;
      $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
      $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
      Sorting.sort(property, obj, this.order);
      $('.'+property).append("  <svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"\n" +
        "                     class=\"turboBusiness-customers-list-table-header-name-icon sort-arrow\" id=\""+ property + "\">\n" +
        "                  <path fill-rule=\"evenodd\" clip-rule=\"evenodd\"\n" +
        "                        d=\"M15.235 12.528c.28-.323.344-.779.164-1.166a1.1 1.1 0 0 0-.999-.635h-3.3V3.091C11.1 2.488 10.608 2 10 2c-.608 0-1.1.488-1.1 1.09v7.637H5.6a1.1 1.1 0 0 0-1 .635c-.179.387-.115.843.165 1.166l4.4 5.091a1.104 1.104 0 0 0 1.67 0l4.4-5.09Z\"\n" +
        "                        fill=\"currentColor\" />\n" +
        "                </svg>");
    }
  }

  getcancelledserialnumberList () {
    this.http.get(Configuration.serviceCoreURL + "/cancelledserialnumbers?type=" + this.type).subscribe(data => {
      this.cancelledserialnumbers = data;
    });
  };

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
    });
    this.getcancelledserialnumberList();
    this.title.setTitle('Octanics - Cancelled Serial Number');
  }
}
